import Entity from './entity';
import { BroadcastingEntity } from './contracts/broadcasting-entity';
import Store from './store';

class AuthenticatedUser extends Entity<AuthenticatedUser> implements BroadcastingEntity {
  /**
   * Nome do utilizador.
   *
   * @var string
   * */
  readonly name: string = '';

  /**
   * Username do utilizador.
   *
   * @var string
   * */
  readonly username: string = '';

  /**
   * Endereço eletrónico do utilizador.
   *
   * @var string
   * */
  readonly email: string = '';

  /**
   * Dados da loja do utilizador.
   */
  store!: Store;

  get firstName(): string {
    return this.name.split(' ')[0];
  }

  /**
   * @inheritDoc
   * */
  getChannelPrefix(): string {
    return `user-${this.id}`;
  }

  /**
   * @inheritDoc
   * */
  setStoreAttribute(store: Store) {
    this.store = Store.from(store);
  }
}

export default AuthenticatedUser;
