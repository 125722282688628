import { NavigationGuard } from 'vue-router/types/router';

import store from '@/store';
import { AuthenticatedUser } from '@/entities';
import { PagesName } from '../routes/pages-enum';

export const FetchStore: NavigationGuard = async (routeTo, routeFrom, next) => {
  const storeIsRequiredForRoute = routeTo.matched.some((route) => route.meta.storeRequired);
  if (!storeIsRequiredForRoute) {
    return next();
  }

  const user = store.getters['auth/user'] as AuthenticatedUser;
  // Registro da store separado para facilitar cenários futuros de multiplas lojas.
  store.commit('store/setStore', user.store);

  const wizardRoutes = [
    PagesName.WIZARD_INIT,
    PagesName.WIZARD_AUTH,
    PagesName.WIZARD_CATEGORY_RELATION,
    PagesName.WIZARD_IMPORT,
    PagesName.WIZARD_OFFER_RELATION,
    PagesName.WIZARD_PRICE_SYNC,
    PagesName.WIZARD_RELATE,
    PagesName.WIZARD_STATUS,
  ];

  const isWizardRoutes = wizardRoutes.includes(routeTo.name as PagesName);

  if (user.store && !user.store.isProvisioned() && !isWizardRoutes) {
    return next({
      name: PagesName.WIZARD_INIT,
      params: {
        marketplace: routeTo.params.marketplace as string,
      },
    });
  }

  return next();
};
