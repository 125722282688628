import vuex from '@/store';
import { CategoryAttributesFinder } from '@/components/marketplace/api/category-attributes-finder';
import { Actions, Getters, Mutations } from './enum';
import CategoryAttribute from '@/entities/category-attributes';
import { ActionTree } from 'vuex';
import { CategoriesState } from './types';
import { RootState } from '@/store/types';
import { CategoryAttributesBinding } from '@/components/marketplace/api/category-attributes-binding';
import { ParamsBindingByRefsAndPage } from '@/components/marketplace/api/types/params-binding-by-refs-and-page';
import { handlerErrorActions } from '../../utils/handler-error-actions';
import { ApiBindingValue } from '@/components/marketplace/api/types/api-binding';
import { TypeRelationship } from '@/components/ui/relationship-table/relationship-table.enum';
import { TypeAttributeBinding } from '@/components/category/type-attribute-binding.enum';

function transformCategory(category: CategoryAttribute): CategoryAttribute {
  /** Resolve o erro de keys duplicadas no front, não remover espaço após o `Marca`*/
  if (category.ref === 'brand') {
    category.label = 'Marca ';
  }
  return CategoryAttribute.from(category);
}

export const actionsAttributes: ActionTree<CategoriesState, RootState> = {
  async [Actions.GET_ATTRIBUTES_MARKETPLACE]({ state, commit }) {
    try {
      const refCategoryMarketplace = state.toBinding.marketplace.selectedCategory?.ref;

      const attributesApi = new CategoryAttributesFinder();

      const data = await attributesApi.allMarketplaceByCategoryRef(refCategoryMarketplace);

      if (!data) return;

      const attributes = data.map((category) => {
        return CategoryAttribute.from(category);
      });

      // TODO: Futuramente deixar os opcionais com paginação carregando com o scroll ou com busca para filtrar a característica desejada
      commit(Mutations.SAVE_ATTRIBUTES_MARKETPLACE_BY_CATEGORY, attributes);
    } catch (error) {
      commit(
        Mutations.SET_ERROR_IN_BINDIG,
        handlerErrorActions({ where: 'Action GET attributes-marketplace', error })
      );
    }
  },

  async [Actions.GET_ATTRIBUTES_VALUES_MARKETPLACE]({ state, getters, commit }) {
    try {
      const attributesApi = new CategoryAttributesFinder();

      const refCategoryMarketplace = state.toBinding.marketplace.selectedCategory?.ref;
      const refOfAttributeLinked = state.toBinding.linked.attributes.marketplace_attribute?.ref;

      if (!refCategoryMarketplace || !refOfAttributeLinked) return;

      const data = await attributesApi.allValuesOfMarketplaceByCategoryAndAttributeRef(
        refCategoryMarketplace,
        refOfAttributeLinked
      );

      if (!data) return;

      commit(Mutations.SAVE_ATTRIBUTES_VALUES_MARKETPLACE_IN_BINDING, data);

      const attributeIsLinked = state.toBinding.marketplace?.attribute?.linked_attribute;
      if (attributeIsLinked) return;

      commit(Mutations.PRE_BINDING_ATTRIBUTES_VALUES_MARKETPLACE_IN_LINKED);
    } catch (error) {
      commit(
        Mutations.SET_ERROR_IN_BINDIG,
        handlerErrorActions({ where: 'Action GET attributes-values-marketplace', error })
      );
    }
  },

  async [Actions.GET_ATTRIBUTES_PLATFORM]({ commit, getters }) {
    try {
      if (getters[Getters.ATTRIBUTES_PLATFORM_IN_BINDING].length === 0) {
        const attributesApi = new CategoryAttributesFinder();
        const refCategoryPlatform = getters[Getters.CATEGORY_PLATFORM_IN_BINDING].ref;

        const data = await attributesApi.allPlatformByCategoryRef(refCategoryPlatform);
        const allData = await attributesApi.allPagesPlatformByCategoryRef(refCategoryPlatform);

        if (!data) return;

        const attributes = data.map(transformCategory);
        const allAttributes = allData.map(transformCategory);

        commit(Mutations.SAVE_ATTRIBUTES_PLATFORM_IN_BINDING, attributes);
        commit(Mutations.SAVE_ALL_ATTRIBUTES_PLATFORM_IN_BINDING, allAttributes);
      }
    } catch (error) {
      commit(
        Mutations.SET_ERROR_IN_BINDIG,
        handlerErrorActions({ where: 'Action GET attributes-platform', error })
      );
    }
  },

  async [Actions.GET_ATTRIBUTES_VALUES_PLATFORM]({ state, getters, commit }) {
    try {
      const attributesApi = new CategoryAttributesFinder();
      const refCategoryPlatform = getters[Getters.CATEGORY_PLATFORM_IN_BINDING].ref;

      const refOfAttributeLinked = state.toBinding.linked.attributes.platform_attribute?.ref;

      if (!refOfAttributeLinked) return;

      const data = await attributesApi.allValuesOfPlatformByCategoryAndAttributeRef(
        refCategoryPlatform,
        refOfAttributeLinked
      );
      if (!data) return;

      commit(Mutations.SAVE_ATTRIBUTES_VALUES_PLATFORM_IN_BINDING, data);

      commit(Mutations.PRE_BINDING_ATTRIBUTES_VALUES_PLATFORM_IN_LINKED);
    } catch (error) {
      commit(
        Mutations.SET_ERROR_IN_BINDIG,
        handlerErrorActions({ where: 'Action GET attributes-values-platform', error })
      );
    }
  },

  async [Actions.GET_ATTRIBUTES_VALUES_BINDING]({ state, commit }) {
    try {
      const attributesApi = new CategoryAttributesBinding();
      const noBinding =
        !state.toBinding.linked.attributes.marketplace_attribute ||
        !state.toBinding.linked.attributes.platform_attribute;
      const type = state.toBinding.linked.attributes.marketplace_attribute?.type;
      const notShouldGetValues = !(
        type === TypeRelationship.SELECT || type === TypeRelationship.NUMBER_UNIT
      );

      if (noBinding && notShouldGetValues) return;

      const params: ParamsBindingByRefsAndPage = {
        marketplace_attribute_ref:
          state.toBinding.linked.attributes.marketplace_attribute?.ref ?? '',
        platform_attribute_ref: state.toBinding.linked.attributes.platform_attribute?.ref ?? '',
        page: 1,
      };

      const data = await attributesApi.allByRefsAndPage(params);

      if (!data || data.length === 0) return;

      const allValues = data[0].values;

      const valuesWithBothValues = allValues.filter((value: ApiBindingValue) => {
        return value.marketplace_attribute_value && value.platform_attribute_value;
      });

      if (allValues.length !== valuesWithBothValues.length) {
        // eslint-disable-next-line no-console
        console.warn('Alguns relacionamentos foram removidos por binding incorreto.');
      }

      const valuesOfAttribute = state.toBinding.linked.attributes.values?.map(
        (attributePreSave) => {
          const isLinkedWithPlatform = valuesWithBothValues.find((responseAttribute) => {
            return (
              responseAttribute.platform_attribute_value.ref.toString() ===
              attributePreSave.platform_attribute_value.ref.toString()
            );
          });

          if (!isLinkedWithPlatform) return attributePreSave;

          return isLinkedWithPlatform;
        }
      );

      commit(Mutations.SAVE_ATTRIBUTES_VALUES_BINDING_IN_LINKED, valuesOfAttribute);
    } catch (error) {
      const message = handlerErrorActions({ where: 'Action GET attributes-binding', error });
      commit(Mutations.SET_ERROR_IN_BINDIG, message);
    }
  },

  async [Actions.POST_BINDING_ATTRIBUTE]({ getters, commit }) {
    try {
      const attributesApi = new CategoryAttributesBinding();

      const dataToSave = getters[Getters.ATTRIBUTES_LINKED_IN_BINDING];

      if (!dataToSave.marketplace_attribute || !dataToSave.platform_attribute) {
        return;
      }

      let validValues = [];
      if (dataToSave.values) {
        validValues = dataToSave.values.filter((current: ApiBindingValue) => {
          return (
            current.marketplace_attribute_value.value && current.platform_attribute_value.value
          );
        });

        validValues = validValues.map((current: ApiBindingValue) => {
          const valuesInFormatAPI = {
            marketplace_attribute_value: current.marketplace_attribute_value.value,
            marketplace_attribute_value_ref: current.marketplace_attribute_value.ref?.toString(),
            marketplace_attribute_value_label: current.marketplace_attribute_value.label,
            marketplace_attribute_value_unit: current.marketplace_attribute_value.unit,
            marketplace_attribute_value_unit_symbol:
              current.marketplace_attribute_value.unit_symbol,
            platform_attribute_value: current.platform_attribute_value.value,
            platform_attribute_value_ref: current.platform_attribute_value.ref.toString(),
            platform_attribute_value_label: current.platform_attribute_value.label,
          };

          if (!current.marketplace_attribute_value.ref) {
            delete valuesInFormatAPI.marketplace_attribute_value_ref;
          }

          if (!current.marketplace_attribute_value.unit) {
            delete valuesInFormatAPI.marketplace_attribute_value_unit;
          }

          if (!current.marketplace_attribute_value.unit_symbol) {
            delete valuesInFormatAPI.marketplace_attribute_value_unit_symbol;
          }

          return valuesInFormatAPI;
        });
      }

      const dataToSaveWithValidValues = {
        ...dataToSave,
        values: validValues,
      };

      if (dataToSaveWithValidValues.values.length === 0) {
        delete dataToSaveWithValidValues.values;
      }

      await attributesApi.saveBinding(dataToSaveWithValidValues);

      commit(
        'uiSettings/setNotification',
        {
          message: 'Relacionamento de atributos salvo com sucesso.',
          title: 'Sucesso',
          variant: 'success',
          autoHideDelay: 5000,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );

      commit(Mutations.UPDATE_ATTRIBUTES_MARKETPLACE_IN_BINDING);
      commit(Mutations.SET_LINKED_CHANGED_TO, true);
    } catch (error: unknown) {
      commit(
        Mutations.SET_ERROR_IN_BINDIG,
        handlerErrorActions({ where: 'Action POST attribute-binding', error })
      );
    }
  },

  [Actions.VALIDATE_IF_EXISTS_ATTRIBUTES_VALUES_AND_UPDATE_LABEL]({ state, getters, commit }) {
    let showNotification = false;

    const attributesLinkedInBinding =
      getters[Getters.ATTRIBUTES_LINKED_IN_BINDING].marketplace_attribute;

    if (!attributesLinkedInBinding) {
      return;
    }

    const typeOfAttribute = attributesLinkedInBinding.type;

    if (typeOfAttribute === TypeAttributeBinding.SELECT) {
      const attributesValuesMarketplace = getters[Getters.ATTRIBUTES_VALUES_MARKETPLACE_IN_BINDING];
      const valuesOfAttribute = getters[Getters.ATTRIBUTES_VALUES_LINKED_IN_BINDING];

      const marketplaceValuesExists = valuesOfAttribute?.map(
        (currentAttribute: ApiBindingValue) => {
          const attributeExists = attributesValuesMarketplace.find(
            (attribute: ApiBindingValue['marketplace_attribute_value']) => {
              return (
                attribute.ref?.toString() ===
                currentAttribute.marketplace_attribute_value.ref?.toString()
              );
            }
          );

          if (!attributeExists && currentAttribute.marketplace_attribute_value?.label) {
            showNotification = true;
            currentAttribute.marketplace_attribute_value = {
              label: '',
              ref: '',
              unit: '',
              unit_symbol: '',
              value: '',
            };
          }

          if (attributeExists) {
            currentAttribute.marketplace_attribute_value.label = attributeExists.label;
          }

          return currentAttribute;
        }
      );

      commit(Mutations.SAVE_ATTRIBUTES_VALUES_MARKETPLACE_FOR_BINDING, marketplaceValuesExists);
    }

    if (typeOfAttribute === TypeAttributeBinding.NUMBER_UNIT) {
      const unitsOfMarketplace = state.toBinding.marketplace.attribute?.units;
      const attributesValuesInBinding = getters[Getters.ATTRIBUTES_LINKED_IN_BINDING].values;

      attributesValuesInBinding.forEach((value: any) => {
        if (value.marketplace_attribute_value.unit) {
          const existsInMarketplace = unitsOfMarketplace?.find((unit) => {
            return unit.value === value.marketplace_attribute_value.unit;
          });

          if (!existsInMarketplace) showNotification = true;
        }
      });
    }

    if (showNotification) {
      vuex.commit('uiSettings/setNotification', {
        where: 'Action GET attributes-values-marketplace',
        message:
          'Algumas opções de características terão que ser relacionadas novamente, pois não existem para esta categoria que está relacionando.',
        title: 'Atenção!',
        variant: 'info',
        toaster: 'b-toaster-top-right',
      });
    }
  },

  async [Actions.VALIDATE_IF_EXISTS_ATTRIBUTES]({ state, commit, dispatch }) {
    const attributesLinkedOfMarketplace = state.attributes.marketplace.filter((attribute) => {
      return attribute.linked_attribute !== null;
    });

    if (attributesLinkedOfMarketplace.length === 0) return;

    await dispatch(Actions.GET_ATTRIBUTES_PLATFORM);

    const attributesOfPlatform = state.toBinding.platform.allAttributes;

    const attributesThatNoLongerExist = attributesLinkedOfMarketplace.filter((attribute) => {
      return !attributesOfPlatform.find((platformAttribute) => {
        return platformAttribute.ref === attribute.linked_attribute!.ref;
      });
    });

    if (attributesThatNoLongerExist.length === 0) return;
    const cleanMarketplaceAttributes = state.attributes.marketplace.map((attribute) => {
      if (attributesThatNoLongerExist.find((item) => item.ref === attribute.ref)) {
        return CategoryAttribute.from({
          ...attribute,
          linked_attribute: null,
        });
      }

      return attribute;
    });
    commit(Mutations.SAVE_ATTRIBUTES_MARKETPLACE_BY_CATEGORY, cleanMarketplaceAttributes);

    const itemsWithIssues = attributesThatNoLongerExist.reduce(
      (acc, attribute: CategoryAttribute): string => {
        return `${acc ? `${acc}, ` : ''}"${attribute.linked_attribute!.label}" (ID: ${attribute.linked_attribute!.ref})`;
      },
      ''
    );
    if (attributesThatNoLongerExist.length === 1) {
      vuex.commit('uiSettings/setNotification', {
        where: 'Action GET VALIDATE_IF_EXISTS_ATTRIBUTES',
        message: `A característica ${itemsWithIssues} não está mais relacionada devido ter sido excluída na loja. Se necessário realize o relacionamento novamente.`,
        title: 'Atenção!',
        variant: 'info',
        toaster: 'b-toaster-top-right',
      });
      return;
    }

    vuex.commit('uiSettings/setNotification', {
      where: 'Action GET VALIDATE_IF_EXISTS_ATTRIBUTES',
      message: `As características ${itemsWithIssues} não estão mais relacionadas devido terem sido excluídas na loja. Se necessário realize o relacionamento novamente.`,
      title: 'Atenção!',
      variant: 'info',
      toaster: 'b-toaster-top-right',
    });
  },
};
